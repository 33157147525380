import React, { useContext } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import { Divider, ListSubheader } from '@mui/material';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CategoryIcon from '@mui/icons-material/Category';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthContext } from '../../contexts/AuthContext';



const menus =  [
    {name: 'Tableau de bord', icon: <DashboardIcon/>, to: '/admin/dashboard'},
    {name: 'Les articles', icon: <ProductionQuantityLimitsIcon/>, to: 'posts'},
    {name: 'Categories', icon: <CategoryIcon/>, to: 'categories'},
    {name: 'Utilisateurs', icon: <PeopleIcon />, to:'users'},
  ]

  
  
  const ListItems = () => {

  const {logout} = useContext(AuthContext)

  const navigate = useNavigate()

  const location = useLocation()

  const logoutFunction = ()=>{

    logout()

    return navigate('/login')

  }

  return (  
    <React.Fragment>
      {menus.map(menu => (
        <ListItemButton key={menu.name} onClick={() => navigate(menu.to)} selected={menu.to === location.pathname}>
            <ListItemIcon>
                {menu.icon}
            </ListItemIcon>
            <ListItemText primary={menu.name} variant="h2"/>
        </ListItemButton>
      ))}
      <Divider/>
      <Divider/>
      <ListSubheader component="div" inset>
        Paramètres
      </ListSubheader>
        <ListItemButton  onClick={() => logoutFunction ()} >
          <ListItemIcon> 
            <LogoutIcon/>
          </ListItemIcon>
          <ListItemText primary="Deconnexion" />
        </ListItemButton>
    </React.Fragment>
  );
}
 
export default ListItems;