import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { variants } from '../motion/variants';


const FrontFooter = () => {

    const quickLinks = [
        {name: 'Carrières',  to:'/'},
        {name: 'A propos',  to:'/'},
        {name: 'Projets',  to:'/'},
        {name: 'Services',  to:'/'},
        {name: 'Notre Blog',  to:'/'},
    ]
    const accounts = [
        {name: 'Votre compte',  to:'/'},
        {name: 'Formation &amp; cours',  to:'/'},
        {name: 'Demander un devis',  to:'/'},
        {name: 'Guide d\'un expert',  to:'/'},
        {name: 'Programme d\'affiliation',  to:'/'},
        {name: 'Administration',  to:'/admin/dashboard'},
    ]
    return (
        <>
            <footer className="site-footer pt-5" style={{backgroundColor: 'black'}}>
                <div className="container">
                    <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <section className="widget widget-light-skin">
                        <h3 className="widget-title">Contactez-nous</h3>
                        <p className="text-white">Phone: +243 971 703 433</p>
                        <ul className="list-unstyled text-sm text-white">
                            <li><span className="opacity-50">Lun - Ven : </span>9h00 - 16h00</li>
                            <li><span className="opacity-50">Samedi : </span>10h00 - 12h00</li>
                        </ul>
                        <p><a className="text-white mb-4" href="mailto:support@uafrika.net" target="_blank"><b><em>support@uafrika.net</em></b></a></p>
                        <div className="col pt-3 pb-3 bg-white text-center mb-4 shadow rounded">
                            <a className="me-3 h2 mt-4" href="#"><i className="bi bi-facebook"></i></a>
                            <a className="me-3 h2" href="#"><i className="bi bi-twitter"></i></a>
                            <a className="me-3 h2" href="#"><i className="bi bi-linkedin"></i></a>
                            <a className="h2" href="#"><i className="bi bi-youtube"></i></a>
                        </div>
                        </section>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <section className="widget widget-light-skin">
                        <h3 className="widget-title"> Nos plateformes</h3>
                        <motion.a 
                            className="market-button apple-button mb-light-skin"
                            variants={variants.btnSpring}
                            whileHover='hover' href="#">
                            <span className="mb-subtitle">Download on the</span>
                            <span className="mb-title">App Store</span>
                        </motion.a>
                        <motion.a 
                            className="market-button google-button mb-light-skin"
                            variants={variants.btnSpring}
                            whileHover='hover' href="#">
                            <span className="mb-subtitle">Download on the</span>
                            <span className="mb-title">Google Play</span>
                        </motion.a>
                        <motion.a 
                            className="market-button windows-button mb-light-skin"
                            variants={variants.btnSpring}
                            whileHover='hover' href="#">
                            <span className="mb-subtitle">Download on the</span>
                            <span className="mb-title">Windows Store</span>
                        </motion.a>
                        </section>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <section className="widget widget-links widget-light-skin">
                        <h3 className="widget-title">A propos de nous</h3>
                        <ul>
                            {quickLinks.map(link =>(
                                <motion.li 
                                    className="pb-2"
                                    variants={variants.btnSpring}
                                    whileHover='active'
                                    >
                                    <Link to={link.to} href="#">{link.name}</Link>
                                </motion.li>
                            ))}
                        </ul>
                        </section>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <section className="widget widget-links widget-light-skin">
                            <h3 className="widget-title">COMPTE &amp; INFO</h3>
                            <ul>
                                {accounts.map(link =>(
                                    <motion.li 
                                        className="pb-2"
                                        variants={variants.btnSpring}
                                        whileHover='active'
                                        >
                                        <Link to={link.to} href="#">{link.name}</Link>
                                    </motion.li>
                                ))}
                            </ul>
                        </section>
                    </div>
                    </div>
                    <hr className="hr-light mt-2 margin-bottom-2x"/>
                    <p className="footer-copyright text-center mt-2">
                        &copy; Tout droit réservés 
                            <a href="http://www.uafrika.net"> 
                                <b> Uafrika plateforme</b>
                            </a> | Site développé par uafrika
                    </p>
                </div>
                </footer> 
        </>
    );
}
 
export default FrontFooter;