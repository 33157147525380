import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, IconButton, InputAdornment, TableCell, TableRow } from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useNavigate } from 'react-router-dom';


export default function SelectOtherProps() {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const navigate = useNavigate()

  const marginTop = 2
  const marginRight = 2

  return (
    <>
      <TableRow>
        <TableCell align="center" colSpan={6}>
          <Button 
            variant='contained' 
            startIcon={<DoneAllIcon/>}
            sx={{mr: (theme) => theme.spacing(75)}}
            onClick={()=> navigate('/admin/dashboard/post/create')}
            >
              Ajouter un article
          </Button>
        </TableCell>
        <TableCell align="center" colSpan={6}>
          <FormControl required sx={{ my: marginTop, mr: marginRight, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-required-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={age}
              label="category"
              size="small"
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                    <IconButton>
                      <CategoryIcon/>
                    </IconButton>
                </InputAdornment>
              } 
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
    </>
  );
}