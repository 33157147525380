import { useContext } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const ProtectedRoutes = () => {

    const location = useLocation()
    
    const {logout} = useContext(AuthContext)

    const user = sessionStorage.getItem('user')

    if(user){

        if(JSON.parse(user).role === 1){

            return <Navigate to="/"/>
        }

        return <Outlet/>
    }

    return <Navigate to='/login' replace state={{ from : location }} />;


};

export default ProtectedRoutes;