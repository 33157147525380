import { useContext } from "react";
import { PostContext } from "../../contexts/PostContext";
import { Link } from "react-router-dom";
import { variants } from '../../component/motion/variants';
import { motion } from 'framer-motion';


const Blog = () => {

    const {posts} = useContext(PostContext)

    return (  
        <>
            {posts.map(post=>(
                <motion.div 
                    variants={variants.posts}
                    initial="hidden"
                    animate="visible"
                    className="col-sm-6" key={post.id}
                    >
                    <div className="blog-grid">
                        <div className="blog-img">
                            <div className="date">
                                <span>{post.created_at.slice(0,2)}</span>
                                <label>{post.created_at.slice(3)}</label>
                            </div>
                            <Link to={'/blog/' + post.id}>
                                <img src={post.image} title="" alt=""/>
                            </Link>
                        </div>
                        <div className="blog-info">
                            <h5>
                                <Link to={'/blog/' + post.id}>
                                    {post.title.slice(0, 60)} ...
                                </Link>
                            </h5>
                            <p>{post.content.slice(0, 70)} ...</p>
                            <div className="btn-bar">
                                <Link to={'/blog/' + post.id} className="px-btn-arrow py-2 ps-2 text-white text-decoration-none" style={{textDecoration: 'none'}}>
                                    <span className="text-decoration-none">Lire tout</span>
                                    <i className="arrow"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </motion.div>
            ))}
        </>
    );
}
 
export default Blog;