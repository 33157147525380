import React from 'react';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';


export default function Deposits(props) {
  return (
    <React.Fragment>
      <Typography variant="h6" color="primary" gutterBottom>
        {props.title}
      </Typography>
      <Typography component="p" variant="h4" sx={{fontWeight: 'bold', color: grey[800]}}>
        ${props.amount}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {props.date}
      </Typography>
    </React.Fragment>
  );
}
