import React, { createContext, useEffect, useState } from 'react';

export const CategoryContext = createContext()

const CategoryContextProvider = (props) => {

    const [categories, setCategories] = useState([])

    const [snack, setSnack] = useState({
        open: false,
        status: '',
        message: ''
    })

    useEffect(()=>{
        fetch('https://uafrika.net/backend/api/categories',{
            headers: {
                'mode': 'cors',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json"},
        })
            .then(response => (response.json()))
            .then(data => setCategories(data.categories))
            .catch(error => console.log(error))
    },[])

    const addCategory = async (name)=>{
        try {
            
            const response = await fetch('https://uafrika.net/backend/api/categories/create',{
                    method: "POST",
                    headers: {
                        'mode': 'cors',
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        "Content-Type": "application/json"},
                    body: JSON.stringify({name})  
                })
    
            const data = await response.json()

            setSnack({open: true, status: 'success', message: data.message})
            
            setCategories([...categories, data.category])

            setTimeout(()=>{
                setSnack({open: false, status: 'success', message: ''})
            }, "3000")

        } catch (error) {

            console.error("Error:", error);
            
        }
        
    }

    const removeCategory = async (id)=>{

        try {
            
            const response = await fetch('https://uafrika.net/backend/api/categories/destroy/'+id,{
                method: "DELETE" ,
                headers: {
                    'mode': 'cors',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    "Content-Type": "application/json"},
            })

            const data =  await response.json()
    
            if (!data.ok) {
                
                setCategories(categories.filter(el => el.id !== id))
                
                setSnack({open: true, status: 'error', message: data.message})

                setTimeout(()=>{
                    setSnack({open: false, status: 'success', message: ''})
                }, "3000")
            }

        } catch (error) {

            console.error("Error:", error);
            
        }
    }

    return ( 
        <CategoryContext.Provider value={{categories, snack, addCategory, removeCategory}}>
            {props.children}
        </CategoryContext.Provider>
     );
}
 
export default CategoryContextProvider;