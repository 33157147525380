import {motion} from 'framer-motion';
import { variants } from '../motion/variants';

const Brand = () => {
  return ( 
    <section class="bg-white h-500 py-5">
        <div class="container">
          <div class="row row-">
            <div class="col-12 col-md-6 col-lg-6 mt-1 px-5">
              <h2 class="h2">A propos</h2>
              <p class="text-dark text-start text-justify" style={{fontSize: '16px'}}>
                Nous sommes ​une entreprise panafricaine qui promeut l'éducation, la technologie et l'entrepreneuriat. Notre mission est de fournir des outils et des ressources pour encourager l'innovation et la croissance durable en Afrique. nous croyons en un continent où chaque rêve trouve les outils pour devenir réalité. 
              </p>
              <motion.button 
                variants={variants.btnSpring}
                whileHover='hover'
                class="btn  btn-success shadow center mt-4 px-5">En savoir plus</motion.button>
            </div>
            <div class="col-12 col-md-6 col-lg-6 mt-4">
              <img src={require('../../assets/style/images/about-img.jpg')} class="img-fluid rounded shadow" alt=""/>
            </div>
          </div>
        </div>
      </section>
   );
}
 
export default Brand;