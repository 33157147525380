import { Link, NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { variants } from '../motion/variants';

export const FrontHeader = () => {

    const navs = [
        {name: 'Accueil', to:'/'},
        {name: 'A propos', to:'/about'},
        {name: 'Portfolio', to:'/portfolio'},
        {name: 'Blog', to:'/blog/home'},
        {name: 'Produits', to:'/blog/home'},
        {name: 'Contact', to:'/contact'},
    ]

    return ( 
        <>
            <motion.header 
                className="bg-white shadow py-2 mb-4 pb px-2 fixed-top"
                variants={variants.headerVariant}
                initial='hidden'
                animate='visible'
                >
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <Link to='/' className="navbar-brand">
                            <motion.img 
                                animate={{
                                    x: [-400, 0, 0, -400, -400, 0, 0, -400, -400, 0]
                                }}
                                transition={{duration: 3, type: 'spring', repeat: Infinity}}
                                src={require('../../assets/style/images/logo.png')} 
                                className="img-fluid" alt="Logo uafrika" width="180" height="50"/>
                        </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            {navs.map(nav =>(
                                <motion.li 
                                    className="nav-item me-1"
                                    variants={variants.btnVariant}
                                    whileHover='active'
                                    >
                                    <NavLink className="nav-link" style={{fontFamily: 'poppins-regular'}} to={nav.to}>{nav.name}</NavLink>
                                </motion.li>
                            ))}
                        </ul>
                        <motion.button 
                            className="btn btn-success  px-4 ms-5 py-2"
                            variants={variants.btnSpring}
                            whileHover='hover'
                            >
                            <a href="callto:+243971703433" className="h6">
                                <i className="bi bi-whatsapp me-2 text-white"></i>
                                <a className="text-white" href="https://wa.me/+243971703433?text=Bonjour%20comment%20puis-je%20vous%20aider$3F" target="_blank ">
                                    +243 971 703 433
                                </a>
                            </a>
                        </motion.button>
                    </div>
                    </div>
                </nav>
            </motion.header>
        </>
     );
}