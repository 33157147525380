import { useContext } from "react";
import { Link, Outlet } from 'react-router-dom';
import SideBar from "../component/front/SideBar";
import { PostContext } from "../contexts/PostContext";
import { CategoryContext } from "../contexts/CategoryContext";
import Partner from "../component/front/Partner";
import { motion } from 'framer-motion';
import { variants } from "../component/motion/variants";


const BlogLayout = () => {

    const {posts} = useContext(PostContext)

    const {categories} = useContext(CategoryContext)

    return (  
        <>
            <motion.section 
                variants={variants.aboutHeader}
                initial="hidden"
                animate="visible"
                className=" pt-5 pb-3 mt-5" 
                style={{backgroundColor: 'rgb(152, 86, 5)'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 mt-1 px-5 text-center pt-3 mx-5">
                            <h2 className="h2 text-underline" style={{color: 'white'}}>Blog d'articles</h2>
                        </div>
                    </div>
                </div>
            </motion.section>
            <section className="blog-listing gray-bg py-4">
                <div className="container">
                    <div className="row align-items-start">
                        <div className="col-lg-8 m-15px-tb">
                            <div className="row">
                                <Outlet/>
                            </div>
                        </div>

                        <motion.div 
                            variants={variants.sideBar}
                            initial="hidden"
                            animate="visible"
                            className="col-lg-4 m-15px-tb blog-aside">
                            <div className="widget widget-author">
                                <div className="widget-title">
                                    <h3>Author</h3>
                                </div>
                                <div className="widget-body">
                                    <div className="media align-items-center">
                                        <motion.div 
                                            style={{backgroundColor: 'rgb(152, 86, 5)'}}
                                            animate={{
                                                rotate: [0, 0, 360, 360, 0],
                                                borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                                            }}
                                            transition={{duration: 4, repeat: Infinity
                                            }}
                                            className="avatar">
                                            <img src={require('../assets/style/images/logo1.png')} title="Uafrika plateforme Logo" alt="Logo de uafrika"/>
                                        </motion.div>
                                        <div className="media-body">
                                            <h6 className="pt-3">Département de la TIC</h6>
                                        </div>
                                    </div>
                                    <p>
                                        Le département TIC d'UAFRIKA est le pilier de l'innovation, propulsant l'éducation et l'entrepreneuriat technologique pour un avenir numérique en Afrique.
                                    </p>
                                </div>
                            </div>
                            <div className="widget widget-post">
                                <div className="widget-title">
                                    <h3>Trending Now</h3>
                                </div>
                                <div className="widget-body">

                                </div>
                            </div>
                            <div className="widget widget-latest-post">
                                <div className="widget-title">
                                    <h3>Latest Post</h3>
                                </div>
                                <div className="widget-body">
                                    {posts.slice(0,3).map(post =>(
                                        <div className="latest-post-aside media">
                                            <div className="lpa-left media-body">
                                                <div className="lpa-title">
                                                    <h5><Link to={'/blog/' + post.id}>{post.title}</Link></h5>
                                                </div>
                                                <div className="lpa-meta">
                                                    <a className="name" href="#">
                                                        {post.category}
                                                    </a>
                                                    <a className="date" href="#">
                                                        {post.created_at}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="lpa-right">
                                                <Link to={'/blog/' + post.id}>
                                                    <img src={post.image} title="" alt=""/>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="widget widget-tags">
                                <div className="widget-title">
                                    <h3>Latest Tags</h3>
                                </div>
                                <div className="widget-body">
                                    <div className="nav tag-cloud">
                                        <a href="#">Design</a>
                                        <a href="#">Development</a>
                                        <a href="#">Travel</a>
                                        <a href="#">Web Design</a>
                                        <a href="#">Marketing</a>
                                        <a href="#">Research</a>
                                        <a href="#">Managment</a>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>
            <Partner/>
        </>
    );
}
 
export default BlogLayout;