import React from 'react';
import Products from '../../component/front/Products';

const FrontProduct = () => {
    return (
        <>
            <Products/>
        </>
     );
}
 
export default FrontProduct;