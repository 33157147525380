import React from 'react';
import {motion} from 'framer-motion';
import { variants } from '../motion/variants';

const Products = () => {

    const products = [
        {
            name: 'Biashara App',
            caption: 'Application de comptable et de gestion',
            image: require('../../assets/style/images/product.jpg')
        },
        {
            name: 'Biashara App',
            caption: 'Application de comptable et de gestion',
            image: require('../../assets/style/images/product.jpg')
        },
        {
            name: 'Biashara App',
            caption: 'Application de comptable et de gestion',
            image: require('../../assets/style/images/product.jpg')
        },
    ]

    
    return ( 
        <>
            <section class="h-500 py-5" style={{backgroundColor: 'rgb(239, 238, 236)'}}>
                <div class="container">
                    <div class="row row-">
                        <div class="col-12 col-md-6 col-lg-6 mt-1 px-5">
                            <h2 class="h2">Découvrez nos Solutions Innovantes</h2>
                            <p class="text-dark text-start text-justify" style={{fontSize: '16px'}}>
                                Nous sommes fiers de vous présenter une gamme de produits et systèmes conçus pour propulser votre entreprise dans l’ère numérique. Notre sélection inclut des solutions modernes, des systèmes d'enseignement et formation en ligne et des applications de gestion comptable et logistique, tous élaborés avec l’objectif de surmonter les défis uniques du continent africain. 
                            </p>
                            <motion.button 
                                variants={variants.btnSpring}
                                whileHover='hover'
                                class="btn  btn-success shadow center mt-4 px-5">
                                <i class="bi bi-whatsapp me-2"></i>
                                <a class="text-white" href="https://wa.me/+243971703433?text=Bonjour%20comment%20puis-je%20vous%20aider$3F" target="_blank ">
                                    CONTACTEZ-NOUS
                                </a>
                            </motion.button>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 mt-4">
                            <div id="carouselExampleDark" class="carousel carousel-dark slide">
                                <div class="carousel-indicators">
                                    <button type="button color-white" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>
                                <div class="carousel-inner">
                                    {products.map(product => (
                                        <div class="carousel-item active" data-bs-interval="10000">
                                            <img src={product.image} class="d-block w-100" alt="..."/>
                                            <div class="carousel-caption d-none d-md-block bg-white rounded shadow-lg">
                                                <h5 class="h5 text-dark"><strong>{product.name}</strong></h5>
                                                <p class="text-dark">{product.caption}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden text-white">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next" style={{color: 'white'}}>
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}
 
export default Products;