import React, { useState } from 'react'
import { Alert, CircularProgress, Snackbar } from '@mui/material';
import { Send } from '@mui/icons-material';
import BookingForm from './forms/BookingForm';

function Booking() {
    const [snack, setSnack] = useState(false)
    const [isPending, setIsPending] = useState(true)
    const send = ()=>{
        setIsPending(true)
        setSnack(true)
        setTimeout(()=>{
            setSnack(false)
            setIsPending(false)
        }, 4000)
    }
  return (
    <section class="bg-white h-500 py-5">
        <Snackbar open={snack} >
            <Alert
                severity='success'
                variant="filled"
                sx={{ width: '100%' }}
            >
                message envoyé, nous vous reviendrons dans ci peu!
            </Alert>
        </Snackbar>
        <div class="container">
            <div class="row row-">
                <div class="col-12 col-md-6 col-lg-6 mt-1 px-5">
                    <h2 class="h2 text-center">Réservez un rendez-vous, en un clic !</h2>
                    <img src={require('../../assets/style/images/rende.jpg')} class="img-fluid rounded pt-3" alt=""/>
                </div>
                <div class="col-12 col-md-6 col-lg-6 mt-4">
                    <BookingForm/>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Booking