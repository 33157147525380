import Typography from '@mui/material/Typography';
import React from 'react';

const BreadCrumb = (props) => {
    return ( 
        <>
            <Typography variant="h4" component="div" sx={{my: 4, fontWeight: 'bold', color: (theme) => theme.palette.primary.main}}>
                {props.title}
            </Typography>
        </>
     );
}
 
export default BreadCrumb;