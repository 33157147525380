import { variants } from "../motion/variants";
import { motion } from 'framer-motion';

const Partner = () => {
    return (  
        <>
            <section class="bg-white py-5 img-fluid partner">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-8 mt-1 px-5 text-center bg-white py-4 shadow rounded mx-5">
                            <h2 class="h2">Avez-vous une idée en tête ? partagez là avec nous !</h2>
                            <p class="text-dark text-start text-center" style={{fontSize: '16px'}}>
                            Nous croyons en un continent où chaque rêve trouve les outils pour devenir réalité. 
                            </p>
                            <motion.button 
                                variants={variants.btnSpring}
                                whileHover='hover'
                                class="btn  btn-success shadow px-3 mt-3">
                                <i class="bi bi-whatsapp me-2"></i>
                                <b> 
                                    <a class="text-white" href="https://wa.me/+243971703433?text=Bonjour%20comment%20puis-je%20vous%20aider$3F" target="_blank ">
                                        Avoir un devis ?
                                    </a>
                                </b>
                            </motion.button>
                        </div>
                    
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default Partner;