import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetMetaData = (props) => {

    const currentUrl = 'https://www.uafrika.net/blog/'+props.postId
    const title = props.title !== undefined? props.title : 'UAFRIKA - PLATEFORME'
    const description = props.description !== undefined? props.description : "UAFRIKA révèle votre potentiel: le succès n'est pas à chercher, il est en vous. Cultivez-le, et laissez-le s'épanouir. C'est notre credo."
    const image = props.image !== undefined? props.image : 'https://uafrika.net/static/media/logo1.d2e059618bb6cd465083.png'
    const hashtag = props.hashtag !== undefined ? props.hashtag : "#uafrika-plateforme";

    return (  
        <Helmet>
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} /> 
            <meta property="og:image" content={image} />
        </Helmet>
    );
}
 
export default HelmetMetaData;