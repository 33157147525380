import { Button } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLoaderData } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { AuthContext } from '../../contexts/AuthContext';
import { PostContext } from '../../contexts/PostContext';
import SocialShare from '../../component/SocialShare';
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import HelmetMetaData from '../../component/HelmetaData';

const BlogDetails = () => {

    const {loginWithGoogle} = useContext(AuthContext)

    const {comment} = useContext(PostContext)

    const[comments, setComments] = useState(null)

    const {post} = useLoaderData()

    const [message, setMessage] = useState('')

    const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('user'))

    const currentUrl = 'https://www.uafrika.net/blog/'+post.id

      useGoogleOneTapLogin({
          onError: error => console.log(error),
          onSuccess: async (response) => {
                  await loginWithGoogle({
                      'name': response.name,
                      'email': response.email,
                      'image': response.picture
                      })
                      
                  setIsAuthenticated(sessionStorage.getItem('user'))
              },
          googleAccountConfigs: {
              client_id: '277945389549-7mmnqv4q8j7pi75ruv16k7f75jvlaic7.apps.googleusercontent.com'
          }
      })
    useEffect(()=>{
        fetch('https://uafrika.net/backend/api/posts/comment/'+post.id)
            .then(response => (response.json()))
            .then(data => setComments(data.comments))
            .catch(error => console.log(error))
    },[])

    const layouting = (content)=>{
        return content.split('*')
    }



    const googleSignUp = useGoogleLogin({

        onSuccess:  async (tokenResponse) => {

            const userInfo = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
              headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
            })
    
            const data = await userInfo.json()
    
            await loginWithGoogle({
              'name': data.name,
              'email': data.email,
              'image': data.picture
            })

            setIsAuthenticated(sessionStorage.getItem('user'))
        },
      });

    const dropComment = async (e)=>{

        e.preventDefault()

        const user = JSON.parse(sessionStorage.getItem('user'))

        const newComment = await comment({
            'post_id': post.id,
            'email': user.email,
            'message': message
        })

        setComments([...comments, newComment])

        setMessage('')
    }


     
    return ( 
        <>
            <HelmetMetaData 
                postId={post.id}
                title={post.title}
                description={post.content}
                image={post.image}>
            </HelmetMetaData>
            <div className="col-lg-12 col-md-12 left-box">
                <div className="card single_post">
                    <div className="body">
                        <div className="py-4 px-3">
                            <img className="d-block img-fluid" src={post.image}/>
                        </div>
                        <h2 className="h4 disabled-5 px-5 py-2">
                            <Link to='/' style={{textDecoration: 'none',color: '#622506', fontFamily: 'poppins-bold'}}>
                                {post.title}
                            </Link>
                        </h2>
                        {layouting(post.content).map(item => (
                            <p className="px-5 text-justify" style={{fontSize: '16px'}}> 
                                {item}
                            </p>
                        ))}
                        <div className="row justify-content-center border-top py-4">
                            <div className="col-8">
                            <h5 className="text-center">Partage sur les réseaux sociaux :</h5>
                            <div className="col pt-3 pb-3 bg-white text-center mb-4 shadow rounded">
                                <SocialShare title={post.title}  url={currentUrl}/>
                            </div>  
                            </div>
                        </div>
                    </div>                        
                </div>
                <div className="card py-4 px-5 my-2">
                        <div className="header pb-3">
                            <h4><b>{comments && comments.length} Commentaires</b></h4>
                        </div>
                        <div className="body">
                            <ul className="comment-reply list-unstyled">
                            {comments && comments.map(comment=>(
                                <li key={comment.id} className="row clearfix py-2">
                                    <div className="icon-box col-md-2 col-4">
                                        <img className="img-fluid img-thumbnail" src={comment.image} alt="Awesome Image"/>
                                    </div>
                                    <div className="text-box col-md-10 col-8 p-l-0 p-r0">
                                        <h6 className=""><b>{comment.userName}</b></h6>
                                        <p style={{fontSize: '13px'}}>{comment.content}</p>
                                        <ul className="list-inline">
                                            <li style={{fontSize: '13px'}}><em>{comment.created_at}</em></li>
                                        </ul>
                                    </div>
                                </li>
                            ))}
                            </ul>                                        
                        </div>
                    </div>
                    <div className="card py-4 px-4">
                        <div className="header">
                            <h5 className="pb-3">Laisser un commentaire, votre adresse mail ne sera pas publiée.</h5>
                        </div>
                        <div className="body">
                            <div className="comment-form pb-3">
                                <form onSubmit={dropComment} className="row clearfix">
                                    <div className="col-sm-12 pb-3">
                                        <div className="form-group pb-3 ">
                                        <textarea 
                                            required
                                            className="form-control w-100" 
                                            cols="30" 
                                            rows="9"
                                            placeholder="Votre commentaire ici"
                                            value={message}
                                            onChange={(e)=> setMessage(e.target.value)}
                                            >
                                        </textarea>
                                        </div>
                                        {isAuthenticated != null ? <button type="submit" 
                                            class="btn btn-block btn-success px-5">COMMENTER</button>
                                            : <Button fullWidth variant="contained" size='large' color='error'
                                                sx={{ mt: 2, mb: 2, height: 40 }}
                                                onClick={googleSignUp}>
                                                <GoogleIcon sx={{ mr: 4 }}/>
                                                Se Connecter avec Google
                                            </Button>
                                        }
                                    </div>                                
                                </form>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );
}
 
export default BlogDetails;

// loader

export const blogLoader = async ({params})=>{

    const {id} = params


    const response = await fetch('https://uafrika.net/backend/api/posts/'+id)

    return response.json()
}