import React, { useEffect } from 'react';
import Brand from '../../component/front/Brand';
import Banner from '../../component/front/Banner';
import Products from '../../component/front/Products';
import Post from '../../component/front/Post';
import Statistic from '../../component/front/Statistic';
import Partner from '../../component/front/Partner';
import Team from '../../component/front/Team';
import CardItems from '../../component/front/CardItems';
import { useNavigate } from 'react-router-dom';
import Booking from '../../component/front/Booking';
import Clients from '../../component/front/Clients';


function Home() {
  return (
    <>
      <Banner/>
      <Brand/>
      <Statistic/>
      <CardItems/>
      <Products/>
      <Booking/>
      <Team/>
      <Post/>
      <Clients/>
      <Partner/>
    </>
  );
}

export default Home;
