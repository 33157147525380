import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { CategoryContext } from '../../contexts/CategoryContext';

export default function SelectOtherProps() {

    const {addCategory} = useContext(CategoryContext)
    const [category, setCategory] = useState('');
    const [categoryError, setCategoryError] = useState(false)
    const [helperText, setHelperText] = useState('')
    const add =  ()=>{
      setCategoryError(false)

      if(category == ''){
        setCategoryError(true)
        setHelperText('Veillez remplir ce champs')
      }else{
        addCategory(category)
        setCategory('')
        setHelperText('')
      }
    }

    return (
      <>
          <TableRow>
              <TableCell align="left" colSpan={6}>
                  <TextField
                    helperText={helperText}
                    error={categoryError} 
                    value={category} 
                    onChange={(e)=> setCategory(e.target.value)} 
                    fullWidth 
                    label="nom de la categorie" 
                    id="fullWidth" 
                    size='small' 
                    sx={{ mr: 4, width: '300px'}}
                  />
                  <Button 
                      onClick={()=> add()}
                      variant='contained' 
                      startIcon={<DoneAllIcon/>} 
                      >
                      Ajouter une categorie
                  </Button>
              </TableCell>
          </TableRow>
      </>
    );
}