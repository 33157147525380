import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { PostContext } from '../../contexts/PostContext';

const Post = () => {

    const {posts} = useContext(PostContext)

    return ( 
        <>
            <section class="section gray-bg" id="blog" style={{backgroundColor: 'rgb(239, 238, 236)'}}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-7 text-center">
                            <div class="section-title">
                                <h2 class="h2">Dernières nouvelles</h2>
                                <p class="text-dark">Nous concevons et développons des services pour des clients de toute taille, spécialisés dans la création de sites Web élégants et modernes.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        {posts.slice(0,3).map(post=>(
                            <div className="col-lg-4" key={post.id}>
                                <div className="blog-grid">
                                    <div className="blog-img">
                                        <div className="date">
                                            <span>{post.created_at.slice(0,2)}</span>
                                            <label>{post.created_at.slice(3)}</label>
                                        </div>
                                        <Link to={'/blog/' + post.id}>
                                            <img src={post.image} title="" alt=""/>
                                        </Link>
                                    </div>
                                    <div className="blog-info">
                                        <h5>
                                            <Link to={'/blog/' + post.id}>
                                                {post.title.slice(0, 60)} ...
                                            </Link>
                                        </h5>
                                        <p>{post.content.slice(0, 70)} ...</p>
                                        <div className="btn-bar">
                                            <Link to={'/blog/' + post.id} className="px-btn-arrow py-2 ps-2 text-white text-decoration-none" style={{textDecoration: 'none'}}>
                                                <span className="text-decoration-none">Lire tout</span>
                                                <i className="arrow"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
     );
}
 
export default Post;