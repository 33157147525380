import React from 'react';
import FrontFooter from '../component/front/FrontFooter';
import ScrollToTop from '../component/ScrollToTop';
import { Outlet, useLocation } from 'react-router-dom';
import { FrontHeader } from '../component/front/FrontHeader';
import { AnimatePresence, motion } from 'framer-motion';
import { variants } from '../component/motion/variants';


const Frontend = () => {
    const location = useLocation()
    return ( 
        <>
            <FrontHeader/>
                <main>  
                    <Outlet/>
                    <ScrollToTop/>
                </main>
            <FrontFooter/>
        </>
     );
}
 
export default Frontend;