import React from 'react';

const CardItems = () => {

    const services = [
        {
            title: 'Éducation Numérique',
            content: 'Découvrez des programmes éducatifs révolutionnaires conçus pour l\'Afrique de demain. Apprenez, grandissez et dépassez les frontières avec UAFRIKA.',
            icon: require('../../assets/style/images/serv-1.jpg')
        },
        {
            title: 'Technologie Avancée',
            content: 'Plongez dans le monde de la technologie avec des solutions qui transforment les idées en succès. Chez UAFRIKA, nous façonnons l\'avenir numérique de l\'Afrique.',
            icon: require('../../assets/style/images/serv-2.jpg')
        },
        {
            title: 'Entrepreneuriat Dynamique',
            content: 'Rejoignez une communauté d\'entrepreneurs visionnaires. Avec UAFRIKA, transformez votre passion en entreprise prospère.',
            icon: require('../../assets/style/images/serv-3.jpg')
        },
    ]
    
    return (  
        <>
            <section className="bg-white py-5">
                <div className="container">
                <h2 className="h2 text-center">Ce Que Nous Faisons</h2>
                <p className="text-dark text-center" >Pourquoi Les Clients Nous Choisissent</p>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {services.map(service =>(
                        <div className="col">
                            <div className="card shadow-lg">
                                <img src={service.icon} className="card-img-top" alt="Image de l'éducation numérique pour le Service uafrika"/>
                                <i className="bi bi-user-graduate"></i>
                                <div className="card-body">
                                <h5 className="h5 card-title text-center">{service.title}</h5>
                                <p className="card-text px-4 text-justify" style={{fontSize: '16px'}}>{service.content}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                </div>
            </section>
        </>
    );
}
 
export default CardItems;