import React, { createContext, useEffect, useState } from 'react';

export const UserContext = createContext()

const UserContextProvider = (props) => {

    const [users, setUsers] = useState([])

    useEffect(()=>{

        fetch('https://uafrika.net/backend/api/users',{
            headers: {
                'mode': 'cors',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json"},
        })
            .then(response => (response.json()))
            .then(data => setUsers(data.users))
            .catch(error => console.log(error))
    },[])
    return ( 
        <UserContext.Provider value={{users}}>
            {props.children}
        </UserContext.Provider>
     );
}
 
export default UserContextProvider;