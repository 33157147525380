import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import BreadCrumb from '../../component/admin/BreadCrumb';
import CategoryHeader from '../../component/admin/CategoryHeader';
import Success from '../../component/admin/snack/Success';
import { CategoryContext } from '../../contexts/CategoryContext';
import { useContext } from 'react';

export default function BasicTable() {

    const {categories, removeCategory, snack} = useContext(CategoryContext)

  return (
    <>
        <BreadCrumb title="Les Categories"/>
        <Success snack={snack}/>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <CategoryHeader/>
                <TableRow>
                    <TableCell align='center'>Nom de la categorie</TableCell>
                    <TableCell align="center">Produits associés</TableCell>
                    <TableCell align="center">Date de creation</TableCell>
                    <TableCell align="center">Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {categories.length == 0 ? (
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                >
                    <TableCell align="center" colSpan={6}>No data available</TableCell>
                </TableRow>
            ):(
                <>
                    {categories.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.posts}</TableCell>
                            <TableCell align="center">{row.created_at}</TableCell>
                            <TableCell align="center">
                                <Button onClick={()=> removeCategory(row.id)} variant='contained' color='error' startIcon={<DeleteIcon />}>Supprimer</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </>
            )}
            </TableBody>
        </Table>
        </TableContainer>
    </>
  );
}
