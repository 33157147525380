import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from '../../component/admin/Chart';
import Deposits from '../../component/admin/Deposits';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {

  const cards = [
    {title:'Total de l\'invenetaire', amount: '3,024.00', date: 'on 15 March, 2023'},
    {title:'Vente journalière', amount: '224,000.00', date: 'on 31 March, 2023'},
    {title:'Vente nettes', amount: '3,025.00', date: 'on 24 March, 2023'},
  ]

  const radius = 4
  return (
    <ThemeProvider theme={defaultTheme}>
        <Grid container spacing={3}>
          {/* Chart */}
            {cards.map(card => (
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 150,
                    borderRadius: radius
                  }}
                >
                  <Deposits title={card.title} amount={card.amount} date={card.date}/>
                </Paper>
              </Grid>
            ))}
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
                borderRadius: radius
              }}
            >
              <Chart />
            </Paper>
          </Grid>
        </Grid>
    </ThemeProvider>
  );
}
