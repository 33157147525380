
import { motion } from 'framer-motion';
import { variants } from '../../component/motion/variants';

const PageHeader = ({title}) => {
    return ( 
        <motion.section 
            className=" pt-5 pb-3 mt-5" 
            style={{backgroundColor: 'rgb(152, 86, 5)'}}
            variants={variants.aboutHeader}
            initial="hidden"
            animate="visible"
            >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 mt-1 px-5 text-center pt-3 mx-5">
                    <h2 className="h2 text-underline" style={{color: 'white'}}>{title}</h2>
                    </div>
                </div>
            </div>
        </motion.section>
     );
}
 
export default PageHeader;