import React from 'react';
import {motion} from 'framer-motion';
import { variants } from '../../component/motion/variants';

const Contact = () => {
    const socials = [
        {icon: <i className="bi bi-facebook"></i>},
        {icon: <i className="bi bi-twitter"></i>},
        {icon: <i className="bi bi-linkedin"></i>},
        {icon: <i className="bi bi-youtube"></i>},
        {icon: <i className="bi bi-whatsapp"></i>},
    ]
    return ( 
        <>
             <motion.section 
                variants={variants.aboutHeader}
                initial="hidden"
                animate="visible"
                className=" pt-5 pb-3 mt-5" 
                style={{backgroundColor: 'rgb(152, 86, 5)'}}>
                <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 mt-1 px-5 text-center pt-3 mx-5">
                    <h2 className="h2 text-underline" style={{color: 'white'}}>Contactez-nous</h2>
                    </div>
                    
                </div>
                </div>
            </motion.section>
            <section className="bg-white h-500 py-5">
                <div className="container">
                    <div className="row row-">
                        <motion.div 
                            variants={variants.posts}
                            initial="hidden"
                            animate="visible"
                            className="col-12 col-md-6 col-lg-6 mt-1 px-5">
                            <h2 className="h2">Contactez-nous</h2>
                            <div className="col pt-3 mt-3 pb-3 bg-dark text-start px-4 mb-4 shadow rounded ">
                            <div className="col">
                                <a className="me-3 mt-4 my-2 text-white" href="#"><i className="bi bi-phone"></i><em className="px-4">+243 971 703 433</em></a>
                            </div>
                            <div className="col py-2">
                                <a className="me-3 text-white" href="#"><i className="bi bi-envelope"></i> <em className="px-4">info@gmail.com</em></a>
                            </div>
                            <div className="col">
                                <a className="me-3 text-white" href="#"><i className="bi bi-geo-alt-fill"></i> <em className="px-4">49, Kagephar, Q. Virunga, Goma-RDC</em></a>
                            </div>
                            </div>
                            <div className="col pt-3 pb-3 bg-white text-center mb-4 shadow rounded">
                            <p>Suivez-nous sur les réseaux sociaux :</p>
                            {socials.map(social=>(
                                <motion.span 
                                    variants={variants.btnSpring}
                                    whileHover='hover'
                                    className="me-5 mt-4 h3" href="#" 
                                    style={{color: '#622506'}}>
                                    {social.icon}
                                </motion.span>
                            ))}
                            </div>
                            <div className="col pt-3 pb-3 bg-white text-center mb-4 shadow rounded">
                            <p className="h2">Suivez notre marque :</p>
                            <a href="https://www.uafrika.net" target="_blank">
                                <motion.img 
                                    animate={{
                                        rotate: [0, 0, 360, 360, 0],
                                        borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                                    }}
                                    transition={{duration: 4, repeat: Infinity
                                    }}
                                    src={require('../../assets/style/images/logo1.png')} 
                                    className="img-fluid" alt="" style={{width: '8rem'}}/>
                            </a>
                            </div>
                        </motion.div>
                        <motion.div 
                            variants={variants.posts1}
                            initial="hidden"
                            animate="visible"
                            className="col-12 col-md-6 col-lg-6 mt-4">
                            <div className="shadow px-5 py-4 rounded">
                            <h5 className="h5">Avez-vous un projet ? Contactez-nous dès maintenant !</h5>
                            <div className="form-group py-2">
                                <label className="fs-4 text-start py-2" for=""><b>Noms :</b></label>
                                <input type="text" name="txtName" className="form-control" placeholder="Votre nom *" value="" />
                            </div>
                            <div className="form-group">
                                <label className="fs-4 text-start py-2" for=""><b>Adresse mail :</b></label>
                                <input type="text" name="txtEmail" className="form-control" placeholder="Votre mail *" value="" />
                            </div>
                            <div className="form-group py-2">
                                <label className="fs-4 text-start py-2" for=""><b>Numéro de téléphone :</b></label>
                                <input type="text" name="txtPhone" className="form-control" placeholder="Your Phone Number *" value="" />
                            </div>
                            
                            <div className="form-group pb-3">
                                <label className="fs-4 text-start py-2" for=""><b>Message :</b></label>
                                <textarea name="txtMsg" className="form-control" placeholder="Votre message *" style={{width: '100%', height: '150px'}}></textarea>
                            </div>
                            <div className="form-group ">
                                <motion.input 
                                    variants={variants.btnSpring}
                                    whileHover='hover'
                                    type="submit" 
                                    name="btnSubmit" 
                                    className="btn btn-success px-5" 
                                    value="Send Message" />
                            </div>
                        </div>
                        </motion.div>
                    </div>
                </div>
            </section>
        </>
     );
}
 
export default Contact;