import React from 'react';
import { FacebookShare, LinkedinShare, TelegramShare, TwitterShare, WhatsappShare } from 'react-share-kit';

const SocialShare = ({title, url}) => {
    return ( 
        <>
            <FacebookShare
                size={45}
                style={{marginRight: 10}}                
                round={true}
                url={url}
                quote={title}
                hashtag={'#uafrika-plateforme'}
            />
            <TwitterShare
                size={45}
                style={{marginRight: 10}}                
                round={true}
                url={url}
                quote={title}
                hashtag={"#uafrika-plateforme"}
            />
            <LinkedinShare
                size={45}
                style={{marginRight: 10}}                
                round={true} 
                url={url}
            />
            <WhatsappShare
                size={45}
                style={{marginRight: 10}}                
                round={true}
                url={url}
                title={'*'+title+'*'}
                separator=" - "
                hashtag={'#uafrika-plateforme'}
                />
            <TelegramShare
                size={45}
                round={true}
                url={url}
                />
        </> 
    );
}
 
export default SocialShare;