import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import PostForm from "../../component/admin/PostForm";

const AddPost = () => {

    return (  
        <>
            <Container component="main" maxWidth="lg">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Paper
                        sx={{
                            p: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            width: 800,
                            borderRadius: 2
                        }}
                    >
                        <Typography variant="h4" sx={{pb: 3, fontWeight: 'bold', color: (theme) => theme.palette.primary.main}}>
                            Nouvel article
                        </Typography>
                        <PostForm/>
                    </Paper>
                </Box>
            </Container>
        </>
    );
}
 
export default AddPost;