import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Clients() {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear"
      };
    const logos = [
        {
            image: require('../../assets/style/images/cefame.jpg')
        },
        {
            image: require('../../assets/style/images/ismgl.jpg')
        },
        {
            image: require('../../assets/style/images/nine.jpg')
        },
        {
            image: require('../../assets/style/images/ml.jpg')
        },
        {
            image: require('../../assets/style/images/sbs.jpg')
        },
    ]
    return (
      <>
        <section class="section bg-white" id="blog" style={{backgroundColor: 'rgb(239, 238, 236)'}}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-7 text-center">
                            <div class="section-title">
                                <h2 class="h2">Nos Partenaires</h2>
                                <p class="text-dark">Nous concevons et développons des services pour des clients de toute taille, spécialisés dans la création de sites Web élégants et modernes.</p>
                            </div>
                        </div>
                    </div>
                    <div className="slider-container">
                        <Slider {...settings}>
                            {logos.map(logo=>(
                                <div className='p-4' style={{width: 100}}>
                                    <img src={logo.image} width="180" height="50"/>
                                </div>
                            ))}

                        </Slider>
                    </div>
                </div>
            </section>
    </>
  )
}

export default Clients