
export const variants ={
    btnSpring: {
        hover: {
            scale:1.3,
            orginX: 0,
            transition:{
                type: 'spring',
                stiffness: 300
            }
        },
        active: {
            scale:1.2,
            x:45,
            orginX: 0,
            color: '#f8e112',
            transition:{
                type: 'spring',
                stiffness: 300
            }
        }
    },    
     headerVariant: {
        hidden: {
            y:'-110vh'
        },
        visible:{
            y: 0,
            transition:{
                duration: 1.5, 
                type: 'spring',
            }
        }
    },
    fadeVariant: {
        hidden: {
            opacity:0
        },
        visible:{
            opacity: 1,
            transition:{
                delay: 1,
                duration: 3,
                mass: 0.4,
                damping: 8,
                when:'beforeChildren',
                staggerChildren: 2 
            }
        }
    },
    textFade1: {
        hidden: {
            opacity:0
        },
        visible:{
            opacity: 1,
            transition:{
                duration: 3,
            }
        }
    },
    textFade2: {
        hidden: {
            opacity:0
        },
        visible:{
            opacity: 1.2,
            transition:{
                delay: 1,
                duration: 3,
            }
        }
    },
    textFade3: {
        hidden: {
            opacity:0
        },
        visible:{
            opacity: 1,
            transition:{
                delay: 1.4,
                duration: 3,
            }
        }
    },
    textFade4: {
        hidden: {
            opacity:0
        },
        visible:{
            opacity: 1,
            transition:{
                delay: 1.6,
                duration: 3,
            }
        }
    },
    btnVariant: {
        hover: {
            scale:1.3,
            orginX: 0,
            transition:{
                type: 'spring',
                stiffness: 300
            }
        },
        hidden: {
            opacity:0
        },
        visible:{
            opacity: 1,
            scale:[1.3,1,1.3,1,1.3,1,1.3,1,1.3,1],
            orginX: 0,
            transition:{
                delay: 2,
                duration: 4,
            }
        }
    },
    routerTrans: {
        hidden: {
            x:'100vw'
        },
        visible:{
            x: 0,
            transition:{
                type: 'spring',
                duration: 1, 
            }
        }
    },
    aboutHeader: {
        hidden: {
            y:'-100vh'
        },
        visible:{
            y: 0,
            transition:{
                delay: 1,
                type: 'spring',
                duration: 1, 
            }
        },
        exit:{
            x: '-100vw',
            transition: {ease: 'easeInOut'}
        }
    },
    posts: {
        hidden: {
            y:'500vh'
        },
        visible:{
            y: 0,
            transition:{
                type: 'spring',
                duration: 1, 
            }
        },
    },
    posts1: {
        hidden: {
            y:'500vh'
        },
        visible:{
            y: 0,
            transition:{
                delay: 0.5,
                type: 'spring',
                duration: 1, 
            }
        },
    },
    sideBar: {
        hidden: {
            x:'100vw'
        },
        visible:{
            x: 0,
            transition:{
                type: 'spring',
                duration: 1, 
            }
        },
        exit:{
            x: '-100vw',
            transition: {ease: 'easeInOut'}
        }
    },
    bannerVariant1: {
        hidden: {
            x:'100vw'
        },
        visible:{
            x: 0,
            transition:{
                delay: 1,
                type: 'spring',
                duration: 1, 
            }
        }
    },
    bannerVariant2: {
        hidden: {
            x:'100vw'
        },
        visible:{
            x: 0,
            transition:{
                delay: 1.5,
                type: 'spring',
                duration: 1, 
            }
        }
    }
}