import React, { useContext, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import logo from '../../assets/style/images/logo1.png'
import { Link } from '@mui/material';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link sx={{fontFamily: 'poppins-bold'}} color="inherit" href="https://mui.com/">
        Uafrika-Plateforme
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {


  const { 
    register, 
    nameError, 
    nameHelper, 
    emailError, 
    emailHelper, 
    passwordError, 
    passwordHelper} = useContext(AuthContext)

  const navigate = useNavigate()

  const [name, setName] = useState('')

  const [email, setEmail] = useState('')

  const [password, setPassword] = useState('')

  const [passwordConfirm, setPasswordConfirm] = useState('')

  const [isPending, setIsPending] = useState(false)

  const handleSubmit = async (e) => {

    e.preventDefault()

    setIsPending(true)

    let status = await register({name, email, password, 'password_confirmation': passwordConfirm})

    setIsPending(false)

    if(status){

        return navigate('/login')
    }


  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar src={logo} sx={{ width: 80, height: 80 }}/>
          <Typography sx={{fontFamily: 'poppins-bold'}} component="h1" variant="h5">
            S'inscrire
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              id="name"
              label="Full name"
              error={nameError}
              helperText={nameHelper}
              value={name}
              onChange={(e)=> setName(e.target.value)}
              autoComplete="name"
              autoFocus
            />
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              error={emailError}
              helperText={emailHelper}
              value={email}
              onChange={(e)=> setEmail(e.target.value)}
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              fullWidth
              error={passwordError}
              helperText={passwordHelper}
              value={password}
              onChange={(e)=>setPassword(e.target.value)}
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              fullWidth
              error={passwordError}
              helperText={passwordHelper}
              value={passwordConfirm}
              onChange={(e)=>setPasswordConfirm(e.target.value)}
              label="Password-confirmation"
              type="password"
              id="password-confirm"
              autoComplete="current-password"
            />
            <Button
              disabled={isPending}
              type="submit"
              fullWidth
              variant="contained"
              size='large'
              sx={{ mt: 3, mb: 4, height: 40 }}
            >
              {
                isPending ? 
                <CircularProgress />
                : (
                    <>
                      <LoginIcon sx={{ mr: 4 }}/>
                      Enregistrer
                    </>
                  )
              }
            </Button>
            <CssBaseline/>
            {/* <Button
              onClick={() => googleSignup()}
              color='error'
              fullWidth
              variant="contained"
              size='large'
              sx={{ mt: 2, height: 40 }}
            >
              <GoogleIcon sx={{ mr: 4 }}/>
              Se Connecter avec Google
            </Button>
            <Button
              disabled={isPending}
              fullWidth
              variant="contained"
              size='large'
              sx={{ mt: 2, mb: 2, height: 40 }}
            >
              <FacebookIcon sx={{ mr: 4 }}/>
              Se Connecter avec facebook
            </Button> */}
            {/* <SocialLogin/> */}
            {/* <NavLink to='/admin/dashboard/categories'>
              <Typography variant='h4'>
                  dasboard
              </Typography>
            </NavLink> */}
            <Grid container>
              <Grid item>
                <Link href="#" variant="body" onClick={()=> navigate('/login')}>
                  {"Do you have an account? Sign In"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

