import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


function Previews(props) {
  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file) 
      })));
      props.parentCallback(acceptedFiles[0])
    }
  });
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src= {file.preview}
          alt="upload picture"
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {  URL.revokeObjectURL(file.preview)  }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="container" id='drag' style={{borderColor: props.error ? 'red' : ''}}>
        <center>
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p style={{color: 'grey'}}>Deposer quelques fichiers, ou clicquer pour en selectionner</p>
            </div>
            <div style={thumbsContainer}>
                {thumbs}
                <div style={thumb} >
                  <div style={thumbInner}>
                    {files.length == 0 ? <img src={props.preview} alt='uploaded picture'/> : ''}
                  </div>
                </div>
            </div> 
        </center>
    </section>
  );
}

<Previews />

export default Previews