import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import EditForm from "../../component/admin/EditForm";
import { useLoaderData } from "react-router-dom";

const EditPost = () => {

    const {post} = useLoaderData()

    return (  
        <>
            
            <Container component="main" maxWidth="lg">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Paper
                        sx={{
                            p: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            width: 800,
                            borderRadius: 2
                        }}
                    >
                        <Typography variant="h4" sx={{pb: 3, fontWeight: 'bold', color: (theme) => theme.palette.primary.main}}>
                            Modification de l'article
                        </Typography>
                        <EditForm post={post}/>
                    </Paper>
                </Box>
            </Container>
            {/* <Box>
            </Box> */}
        </>
    );
}
 
export default EditPost;

export const postLoader = async ({params})=>{

    const {id} = params


    const response = await fetch('https://uafrika.net/backend/api/posts/'+id)

    return response.json()
}