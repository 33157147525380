import React from 'react';

const Team = () => {

    const teams = [
        {
            name: 'Dieudonné MASUDI',
            title: 'Directeur Général',
            image: require('../../assets/style/images/team1.jpg')
        },
        {
            name: 'Yvette RIZIKI',
            title: 'Finance Manager',
            image: require('../../assets/style/images/team5.jpg')
        },
        {
            name: 'Michael KAZINGUFU',
            title: 'Production Manager',
            image: require('../../assets/style/images/team3.jpg')
        },
        {
            name: 'SIFA Prisca',
            title: 'Community manager',
            image: require('../../assets/style/images/team6.jpg')
        },
        {
            name: 'Jean KASUSA',
            title: 'Program manager',
            image: require('../../assets/style/images/team4.jpg')
        },
    ]

    return (  
        <>
            <section class=" bg-white py-5">
                <div class="container">
                <h2 class="h2 pb-1 text-center">Notre équipe experte est toujours prêt</h2>
                <p class="text-dark text-center pb-4">L'équipe derrière Uafrika</p>
                <div class="row row-cols-1 row-cols-md-5 g-4">
                    {teams.map(team => (
                        <div class="col">
                            <div class="card shadow">
                                <img src={team.image} class="card-img-top" alt="Image photo de l'équipe de uafrika Directeur Générale"/>
                                <div class="card-body">
                                <h5 class="h6 text-center">{team.name}</h5>
                                <p class="card-text text-center" style={{fontSize: '12px'}}>{team.title}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                </div>
            </section>
        </>
    );
}
 
export default Team;