import React, { createContext, useEffect, useState } from 'react';

export const FrontContext = createContext()

const FrontContextProvider = (props) => {

    const sendMessage = async (data)=>{
        const req = await fetch('https://uafrika.net/backend/api/send/message',{
            method: 'POST',
            headers:{
                'mode': 'cors',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json"},
            body: JSON.stringify(data)
        })

        const res = await req.json()
        console.log(res)

        if(!res.ok){
            if('status' in res){
                return true
            }
        }

    }

    return ( 
        <FrontContext.Provider value={{sendMessage}}>
            {props.children}
        </FrontContext.Provider>
     );
}
 
export default FrontContextProvider;