import './App.css';
import Products from './pages/admin/Products';
import Categories from './pages/admin/Categories';
import Users from './pages/admin/Users';
import Admin from './layouts/Admin';
import Dashboard from './pages/admin/Dashboard';
import CategoryContextProvider from './contexts/CategoryContext';
import Home from './pages/front/Home';
import About from './pages/front/About';
import FrontProduct from './pages/front/FrontProduct';
import Contact from './pages/front/Contact';
import Login from './pages/front/Login';
import Register from './pages/front/Register';
import Blog from './pages/front/Blog';
import BlogDetails, { blogLoader } from './pages/front/BlogDetails';
import BlogLayout from './layouts/BlogLayout';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import AddPost from './pages/admin/AddPost';
import EditPost, { postLoader } from './pages/admin/EditPost';
import PostContextProvider from './contexts/PostContext';
import AuthContextProvider from './contexts/AuthContext';
import ProtectedRoutes from './component/ProtectedRoutes';
import UserContextProvider from './contexts/UserContext';
import Frontend from './layouts/Frontend';
import { Portfolio } from './pages/front/Portfolio';
import { Helmet } from 'react-helmet';
import HelmetMetaData from './component/HelmetaData';
import FrontContextProvider from './contexts/FrontContext';

const router = createBrowserRouter(

  createRoutesFromElements(
    <>
        <Route path='/' element={<Frontend/>}>
          <Route index element={<Home/>}/>
          <Route path='about' element={<About/>}/>
          <Route path='projects' element={<FrontProduct/>}/>
          <Route path='blog' element={<BlogLayout/>}>
            <Route path='home' element={<Blog/>}/>
            <Route path=':id' element={<BlogDetails/>} loader={blogLoader}/>
          </Route>
          <Route path='contact' element={<Contact/>}/>
          <Route path='portfolio' element={<Portfolio/>}/>
        </Route>
        <Route path='/admin' element={<ProtectedRoutes/>}>
          <Route path='dashboard' element={<Admin/>}>
            <Route index element={<Dashboard/>}/>
            <Route path='categories' element={<Categories/>}/>
            <Route path='posts' element={<Products/>}/>
            <Route path='users' element={<Users/>}/>
            <Route path='post/create' element={<AddPost/>}/>
            <Route path='post/edit/:id' element={<EditPost/>} loader={postLoader} />
          </Route>
        </Route>
        <Route path='login' element={<Login/>}/>
        <Route path='register' element={<Register/>}/>
    </>
  )
)

function App() {
  return (
    <>
      <HelmetMetaData/>
      <AuthContextProvider>
        <PostContextProvider>
          <CategoryContextProvider>
            <UserContextProvider>
              <FrontContextProvider>
                <RouterProvider router={router}/>
              </FrontContextProvider>
            </UserContextProvider>
          </CategoryContextProvider>
        </PostContextProvider>
      </AuthContextProvider>
    </>
  );
}

export default App;
