import React, { createContext, useEffect, useState } from 'react';

export const PostContext = createContext()

const PostContextProvider = (props) => {

    const [posts, setPosts] = useState([])

    const [post, setPost] = useState(null)

    const [snack, setSnack] = useState({
        open: false,
        status: '',
        message: ''
    })

    const [titleHelper, setTitleHelper] = useState('')

    const [titleError, setTitleError] = useState(false)

    const [categoryHelper, setCategoryHelper] = useState('')

    const [categoryError, setCategoryError] = useState(false)

    const [contentHelper, setContentHelper] = useState('')

    const [contentError, setContentError] = useState(false)

    const [imageHelper, setImageHelper] = useState('')

    const [imageError, setImageError] = useState(false)

    useEffect(()=>{
        fetch('https://uafrika.net/backend/api/posts',{
            headers: {
                'mode': 'cors',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json"},
        })
            .then(response => (response.json()))
            .then(data => setPosts(data.posts))
            .catch(error => console.log(error))
    },[])

    const addPost = async (post)=>{
        try {

            let formData = new FormData()
            formData.append('image',post.image)
            formData.append('title',post.title)
            formData.append('content',post.content)
            formData.append('category',post.category)

            
            const response = await fetch('https://uafrika.net/backend/api/posts/create',{
                    method: "POST",
                    headers: {
                        'mode': 'cors',
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        "Content-Type": "application/json"},
                    body: formData
                })
    
            const data = await response.json()

            setTitleError(false)
            setTitleHelper('')
            setCategoryError(false)
            setCategoryHelper('')
            setContentError(false)
            setContentHelper('')
            setImageError(false)
            setImageHelper('')

            if(!data.ok){

                if('error' in data){
                    
                    if('title' in data.error){
                        setTitleError(true)
                        setTitleHelper(data.error.title[0])
                    }
                    if('category' in data.error){
                        setCategoryError(true)
                        setCategoryHelper(data.error.category[0])
                    }
                    if('content' in data.error){
                        setContentError(true)
                        setContentHelper(data.error.content[0])
                    }
                    if('image' in data.error){
                        setImageError(true)
                        setImageHelper(data.error.image[0])
                    }

                    return false
                }

                setPosts([...posts, data.values])

                setSnack({open: true, status: 'success', message: data.message})

                setTimeout(()=>{
                    setSnack({open: false, status: 'success', message: ''})
                }, "3000")

                return true

            }else{
                console.log('not okay')
            }

        } catch (error) {

            console.log( error)
            
        }
        
    }
    const editPost = async (id, post)=>{
        try {

            let formData = new FormData()
            if (post.image) {
                
                formData.append('image',post.image)
            }
            formData.append('title',post.title)
            formData.append('content',post.content)
            formData.append('category',post.category)
            console.log(post)

            
            const response = await fetch('https://uafrika.net/backend/api/posts/edit/'+id,{
                    method: "PUT",
                    headers: {
                        'mode': 'cors',
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        "Content-Type": "application/json"},
                    body: JSON.stringify(formData)
                })
    
            const data = await response.json()

            setTitleError(false)
            setTitleHelper('')
            setCategoryError(false)
            setCategoryHelper('')
            setContentError(false)
            setContentHelper('')
            setImageError(false)
            setImageHelper('')

            if(!data.ok){

                if('error' in data){
                    
                    if('title' in data.error){
                        setTitleError(true)
                        setTitleHelper(data.error.title[0])
                    }
                    if('category' in data.error){
                        setCategoryError(true)
                        setCategoryHelper(data.error.category[0])
                    }
                    if('content' in data.error){
                        setContentError(true)
                        setContentHelper(data.error.content[0])
                    }

                    return false
                }

                setSnack({open: true, status: 'success', message: data.message})

                setTimeout(()=>{
                    setSnack({open: false, status: 'success', message: ''})
                }, "3000")

                return true

            }else{
                console.log('not okay')
            }

        } catch (error) {

            console.log( error)
            
        }
        
    }

    const comment = async (commentData)=>{
        try {

            const response = await fetch('https://uafrika.net/backend/api/posts/comment',{
                    method: "POST",
                    headers: {
                        'mode': 'cors',
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        "Content-Type": "application/json"},
                    body: JSON.stringify(commentData)
                })
    
            const data = await response.json()

            if(!data.ok){

                return data

            }else{
                console.log('not okay')
            }

        } catch (error) {

            console.log( error)
            
        }
        
    }

    const removePost = async (id)=>{

        try {
            
            const response = await fetch('https://uafrika.net/backend/api/posts/destroy/'+id,{
                method: "DELETE",
                headers: {
                    'mode': 'cors',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    "Content-Type": "application/json"},
            })

            const data =  await response.json()
    
            if (!data.ok) {
                
                setPosts(posts.filter(el => el.id !== id))
                
                setSnack({open: true, status: 'error', message: data.message})

                setTimeout(()=>{
                    setSnack({open: false, status: 'success', message: ''})
                }, "3000")

                
            }

        } catch (error) {

            console.error("Error:", error);
            
        }
    }

    const getPost = async (id)=>{

        try {

            const response = await fetch('https://uafrika.net/backend/api/posts/'+id,{
                headers: {
                    'mode': 'cors',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    "Content-Type": "application/json"},
            })

            const data = await response.json()

            if(!data.ok){

                setPost(data.post)

            }else{
                console.log('not okay')
            }
            
        } catch (error) {

            console.log(error)
            
        }
    }

    return ( 
        <PostContext.Provider value={{
            titleError,
            titleHelper,
            categoryError,
            categoryHelper,
            contentError,
            contentHelper,
            imageHelper,
            imageError,
            posts, 
            snack, 
            addPost,
            editPost, 
            removePost, 
            getPost, 
            post, 
            comment}}>
            {props.children}
        </PostContext.Provider>
     );
}
 
export default PostContextProvider;