import { useState } from 'react';
import { variants } from '../../component/motion/variants';
import { motion } from 'framer-motion';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

export const Portfolio = () => {

    const [selectedId, setSelectedId] = useState(null)

    const trends = [
        {
            icon: 'fa fa-file',
            num: '938',
            caption: 'Posts'
        },
        {
            icon: 'fa fa-user',
            num: '3,586',
            caption: 'Abonnés'
        },
        {
            icon: 'fa fa-share',
            num: '2,659',
            caption: 'Partage'
        },
    ]

    const projects = [
        {id: 1, image: require('../../assets/style/images/aaaa.jpg')},
        {id: 2, image: require('../../assets/style/images/aaaa.jpg')},
        {id: 3, image: require('../../assets/style/images/aaaa.jpg')},
        {id: 4, image: require('../../assets/style/images/aaaa.jpg')},
        {id: 5, image: require('../../assets/style/images/aaaa.jpg')},
        {id: 6, image: require('../../assets/style/images/aaaa.jpg')},
    ]

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = ()=>{
        setOpen(true)
    }
    return (  
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        color: (theme) => theme.palette.main,
                    }}
                    >
                    <CloseIcon/>
                </IconButton>
                <DialogContent>
                    <img style={{height: 400}} src={require('../../assets/style/images/aaaa.jpg')}/>
                </DialogContent>
            </BootstrapDialog>
            <motion.section 
                variants={variants.aboutHeader}
                initial="hidden"
                animate="visible"
                className=" pt-5 pb-3 mt-5" 
                style={{backgroundColor: 'rgb(152, 86, 5)'}}
                >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 mt-1 px-5 text-center pt-3 mx-5">
                        <h2 className="h2 text-underline" style={{color: 'white'}}>Notre portofolio</h2>
                        </div>
                    </div>
                </div>
            </motion.section>
            <motion.div 
                variants={variants.routerTrans}
                initial="hidden"
                animate="visible"
                className="container my-3"
                >
                <div className="card overflow-hidden">
                <div className="card-body p-0">
                    <img src={require('../../assets/style/images/cta.jpg')} alt="" className="img-fluid"/>
                    <div className="row align-items-center px-3 py-4">
                    <div className="col-lg-4 order-lg-1 order-2">
                        <div className="d-flex align-items-center justify-content-around m-4">
                            {trends.map(trend => (
                                <div  key={trend.icon} className="text-center">
                                    <i className={trend.icon + ' fs-6 d-block mb-2'}></i>
                                    <h4 className="mb-0 fw-semibold lh-1">{trend.num}</h4>
                                    <p className="mb-0 fs-2">{trend.caption}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-4 mt-n3 order-lg-2 order-1">
                        <div className="mt-n5">
                        <div className="d-flex align-items-center justify-content-center mb-2">
                            <div className="linear-gradient d-flex align-items-center justify-content-center rounded-circle" style={{width: '110px', height: '110px'}}>
                                <div className="border border-4 border-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden" style={{width: '100px', height: '100px'}}>
                                    <img src={require('../../assets/style/images/logo1.png')} alt="" className="w-100 h-100"/>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <h5 className="fs-5 mb-0 fw-semibold">Projets réalisés</h5>
                            <p className="mb-0 fs-4">Design</p>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-4 order-last">
                        <p className="me-2">Partager : </p>
                        <a className="me-3 h2" href="#"><i className="bi bi-facebook"></i></a>
                        <a className="me-3 h2" href="#"><i className="bi bi-twitter"></i></a>
                        <a className="me-3 h2" href="#"><i className="bi bi-linkedin"></i></a>
                        <a className="h2" href="#"><i className="bi bi-whatsapp"></i></a>
                    </div>
                    </div>
                </div>
                </div>
                <div className="tab-content" id="pills-tabContent">
            
                <div className="tab-pane fade show active" id="pills-gallery" role="tabpanel" aria-labelledby="pills-gallery-tab" tabindex="0">
                    <div className="d-sm-flex align-items-center justify-content-between mt-3 mb-4">
                    <h3 className="mb-3 mb-sm-0 fw-semibold d-flex align-items-center">Gallerie <span className="badge bg-danger text-bg-secondary fs-2 rounded-4 py-1 px-2 ms-2">12</span></h3>
                    <form className="position-relative">
                        <input type="text" className="form-control search-chat py-2 ps-5" id="text-srh" placeholder="Tapez votre mot clé..."/>
                        <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y text-dark ms-3"></i>
                    </form>
                    </div>
                    <div className="row">
                        {projects.map(project =>(
                            <div className="col-md-6 col-lg-4" onClick={handleOpen}>
                                <div className="card hover-img overflow-hidden rounded-2">
                                <div className="card-body p-0">
                                    <img src={project.image} className="img-fluid w-100 object-fit-cover" style={{height: '220px'}}/>
                                    <div className="p-4 d-flex align-items-center justify-content-between">
                                    <div>
                                        <h6 className="fw-semibold mb-0 fs-4">Isuava wakceajo fe.jpg</h6>
                                        <span className="text-dark fs-2">Wed, Dec 14, 2023</span>
                                    </div>
                                    <div className="dropdown">
                                        <a className="text-muted fw-semibold d-flex align-items-center p-1" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="ti ti-dots-vertical"></i>
                                        </a>
                                        <ul className="dropdown-menu overflow-hidden">
                                        <li><a className="dropdown-item" href="#">Isuava wakceajo fe.jpg</a></li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                </div>
            </motion.div>
        </>
    );
}