import React, { createContext, useState } from 'react';

export const AuthContext = createContext()

const AuthContextProvider = (props)=>{  

    const [isAuth, setIsAuth] = useState(false)

    const [emailError, setEmailError] = useState(false)

    const [nameError, setNameError] = useState(false)

    const [passwordError, setPasswordError] = useState(false)

    const [emailHelper, setEmailHelper] = useState(false)

    const [nameHelper, setNameHelper] = useState(false)

    const [passwordHelper, setPasswordHelper] = useState('')

    const [alert, setAlert] = useState({
        open: false,
        status: '',
        message: ''
    })

    const register = async (credentials)=>{
        try {
            const response = await fetch('https://uafrika.net/backend/api/register',{
                    method: "POST",
                    headers: {
                        'mode': 'cors',
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        "Content-Type": "application/json"},
                    credentials: 'include',
                    body: JSON.stringify(credentials)  
                })
            
            const data = await response.json()

            setNameError(false)
            setEmailError(false)
            setPasswordError(false)

            if(!data.ok){
                if('error' in data){
                    
                    if('email' in data.error){
                        setEmailError(true)
                        setEmailHelper(data.error.email[0])
                    }
                    if('name' in data.error){
                        setNameError(true)
                        setNameHelper(data.error.name[0])
                    }
                    if('password' in data.error){
                        setPasswordError(true)
                        setPasswordHelper(data.error.password[0])
                    }

                    return false
                }
                
                sessionStorage.setItem('user', JSON.stringify(data.user))

                return true


            }

        } catch (error) {
            console.log(error)
            
        }
    }
    const login = async (credentials)=>{
        try {
            const response = await fetch('https://uafrika.net/backend/api/login',{
                method: 'POST',
                headers:{
                    'mode': 'cors',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                credentials: 'include',
                body: JSON.stringify(credentials)  
            })
            
            const data = await response.json()

            setEmailError(false)
            setPasswordError(false)

            if(!data.ok){
                
                if('error' in data){
                    
                    if('email' in data.error){
                        setEmailError(true)
                        setEmailHelper(data.error.email[0])
                    }
                    if('password' in data.error){
                        setPasswordError(true)
                        setPasswordHelper(data.error.password[0])
                    }

                    setAlert({open: true, status: 'error', message: data.message})

                    setTimeout(()=>{
                        setAlert({open: false, status: 'error', message: ''})
                    }, "3000")

                    return false
                }

                if('user' in data){

                    setAlert({open: true, status: 'success', message: data.message})
    
                    setTimeout(()=>{
                        setAlert({open: false, status: 'error', message: ''})
                    }, "3000")

                    sessionStorage.setItem('user', JSON.stringify(data.user))

                    return true
                }
            }




        } catch (error) {
            console.log(error)
            
        }
    }

    const loginWithGoogle = async (credentials)=>{

        try {

            const response = await fetch('https://uafrika.net/backend/api/loginWithGoogle',{
                    method: "POST",
                    headers: {
                        'mode': 'cors',
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        "Content-Type": "application/json"},
                    credentials: 'include',
                    body: JSON.stringify(credentials)  
                })
            
            const data = await response.json()

            if(!data.ok){

                sessionStorage.setItem('user', JSON.stringify(data.user))

                return true

            }
            
        } catch (error) {
            
        }
    }

    const logout = async ()=>{

        const response = await fetch('https://uafrika.net/backend/api/logout',{
                    method: "POST",
                    headers: {
                        'mode': 'cors',
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        "Content-Type": "application/json"},
                    credentials: 'include'
                })

        sessionStorage.removeItem("user");
        
    }

    return (
        <AuthContext.Provider 
        value={{
            login,
            register,
            logout,
            loginWithGoogle,
            alert,
            isAuth,
            nameError,
            nameHelper,
            emailError,
            emailHelper,
            passwordError,
            passwordHelper  
            }}>
            {props.children}
        </AuthContext.Provider>
    )

}

export default AuthContextProvider

