import React, { useState, useContext, useEffect } from 'react';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import UploadImage from "./UploadImage"
import { CategoryContext } from '../../contexts/CategoryContext';
import { PostContext } from '../../contexts/PostContext';
import { useNavigate } from 'react-router-dom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { CircularProgress, Typography } from '@mui/material';

const PostForm = ({post}) => {

    const navigate = useNavigate()

    // data init

    const {categories} = useContext(CategoryContext)

    const{
        addPost,
        editPost,
        titleError,
        titleHelper,
        categoryError,
        categoryHelper,
        contentError,
        contentHelper,
        imageError,
        imageHelper} = useContext(PostContext)

    const [title, setTitle] = useState('')

    const [category, setCategory] = useState('')

    const [content, setContent] = useState('')

    const [image, setImage] = useState(null)

    const [isPending, setIsPending] = useState(false)

    useEffect(()=>{
        if (post !== undefined) {
            let category = categories.find(el => el.name !== post.category)
            setTitle(post.title)
            setCategory(category.id)
            setContent(post.content)
            
        }
    })

    const handleSubmit = async(e)=>{

        e.preventDefault()

        setIsPending(true)

        let status = await addPost({title, category, content, image})

        setIsPending(false)

        if(status){

            setTitle('')
      
            setCategory('')

            setContent('')

            setImage(null)
      
            navigate('/admin/dashboard/posts')
      
          }

    }

    const editCurrentPost = async (e)=>{

        e.preventDefault()

        setIsPending(true)

        console.log('here')

        let status = await editPost(post.id, {title, category, content, image})

        setIsPending(false)

        if(status){

            setTitle('')
      
            setCategory('')

            setContent('')

            setImage(null)
      
            navigate('/admin/dashboard/posts')
      
          }
    }

    const getImage = (img)=>{
        
        setImage(img)

    }

    return ( 
        <>
            <form onSubmit={post === undefined ? handleSubmit : editCurrentPost}>
                <TextField
                    helpertext={titleHelper}
                    error={titleError}  
                    value={title} 
                    onChange={(e)=> setTitle(e.target.value)} 
                    fullWidth
                    label="Titre de l'article" 
                    id="fullWidth" 
                    size='medium'

                />
                <FormControl fullWidth sx={{ my: 2}}>
                    <InputLabel id="demo-simple-select-required-label">Category</InputLabel>
                    <Select
                        helpertext={categoryHelper}
                        error={categoryError} 
                        fullWidth
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={category} 
                        onChange={(e)=> setCategory(e.target.value)} 
                        label="category de l'article"
                        size="medium" 
                    >
                        {categories.map(category => (
                            <MenuItem value={category.id} key={category.id}>{category.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    helpertext={contentHelper}
                    error={contentError} 
                    value={content} 
                    onChange={(e)=> setContent(e.target.value)} 
                    fullWidth
                    label="Contenu de l'article" 
                    multiline
                    rows={5}
                    size='medium' 
                    sx={{ mb: 2 }}
                />
                <UploadImage 
                    parentCallback= {getImage} 
                    error={imageError} 
                    preview={post !== undefined ? post.image : null}/>
                <Typography variant='body2' color='error'>
                    {imageHelper && imageHelper}
                </Typography>
                <Button
                    disabled={isPending}
                    type="submit"
                    variant="contained"
                    size='large'
                    sx={{ mt: 3, mb: 4, height: 40 }}
                    >
                    {
                        isPending ? 
                        <CircularProgress />
                        : (
                            <>
                            <DoneAllIcon sx={{ mr: 4 }}/>
                            {post !== undefined ? 'Sauvegarder' : 'Enregistrer'}
                            
                            </>
                        )
                    }
                </Button>
            </form>
        </>
     );
}
 
export default PostForm;