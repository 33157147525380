import React from 'react';
import { motion } from 'framer-motion';
import { variants } from '../motion/variants';

const Banner = () => {
    const contact = ()=>{
        console.log('in the shit')
        // window.scrollTo(500, 0);
        window.scrollTo(0,2300);
    }

    return (  
        <>
            <motion.section 
                className=" banner img-fluid mt-5"
                variants={variants.fadeVariant}
                initial="hidden"
                animate="visible"
                >
                <div className="container py-5 mt-3">
                    <div className="row">
                        <div className="col col-lg-6 py-5 mt-4">
                            <motion.h3 
                                className="display-5 text-white mt-"
                                variants={variants.bannerVariant1}
                                initial="hidden"
                                animate="visible">
                                    Explorez un monde de connaissances,d’innovation et d’opportunités
                            </motion.h3>
                            <motion.p 
                                className="py-2 text-white"
                                variants={variants.bannerVariant2}
                                initial="hidden"
                                animate="visible">
                                    Rejoignez-nous pour façonner l’avenir de l’Afrique à travers l’éducation, la technologie et l’entrepreneuriat. Construisons ensemble un continent plus radieux !
                            </motion.p>
                            <motion.button 
                                className="btn  px-5 shadow mt-4"
                                variants={variants.btnVariant}
                                initial="hidden"
                                animate="visible"
                                whileHover='hover'
                                onClick={()=> contact()}
                                >COMMENCER
                            </motion.button>
                        </div>
                        <div className="col col-lg-6 py-5 mt-4 justify-content-center" style={{ paddingLeft: 300}}>
                        </div>
                    </div>
                </div>
            </motion.section>
        </>
    );
}
 
export default Banner;