import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import BreadCrumb from '../../component/admin/BreadCrumb';
import Success from '../../component/admin/snack/Success';
import ProductHeader from '../../component/admin/ProductHeader';
import { useContext } from 'react';
import { PostContext } from '../../contexts/PostContext';
import { useNavigate } from 'react-router-dom';

export default function BasicTable() {

    const navigate = useNavigate()

    const {posts, removePost, snack} = useContext(PostContext)

  return (
    <>
        <Success snack={snack}/>
        <BreadCrumb title="Les Articles"/>
        <TableContainer component={Paper}>
            <ProductHeader/>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell align='center'>Titre de l'article</TableCell>
                    <TableCell align="center">Categorie</TableCell>
                    <TableCell align="center">Vues</TableCell>
                    <TableCell align="center">Date de creation</TableCell>
                    <TableCell align="center">Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {posts.length == 0 ? (
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                >
                    <TableCell align="center" colSpan={6}>No data available</TableCell>
                </TableRow>
            ):(
                <>
                    {posts.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="center">{row.title}</TableCell>
                            <TableCell align="center">{row.category}</TableCell>
                            <TableCell align="center">{row.vues}</TableCell>
                            <TableCell align="center">{row.created_at}</TableCell>
                            <TableCell align="center">
                                <Button 
                                  variant='contained' 
                                  sx={{mr: 2}} 
                                  color='primary' 
                                  startIcon={<DeleteIcon />}
                                  onClick={()=> navigate('/admin/dashboard/post/edit/'+row.id)}
                                >Editer</Button>

                                <Button 
                                  onClick={()=> removePost(row.id)}
                                  variant='contained' 
                                  color='error' 
                                  startIcon={<DeleteIcon />}
                                >Supprimer</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </>
            )}
            </TableBody>
        </Table>
        </TableContainer>
    </>
  );
}
