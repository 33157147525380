import React, { useContext, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from '../../contexts/AuthContext';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import logo from '../../assets/style/images/logo1.png'
import { Alert, Link } from '@mui/material';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link sx={{fontFamily: 'poppins-bold'}} color="inherit" href="https://mui.com/">
        Uafrika-Plateforme
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {

  const {login, alert, emailError, emailHelper, passwordError, passwordHelper, loginWithGoogle} = useContext(AuthContext)

  const navigate = useNavigate()

  const location = useLocation()

  const [email, setEmail] = useState('')

  const [password, setPassword] = useState('')

  const [isPending, setIsPending] = useState(false)

  const handleSubmit = async (e) => {

    e.preventDefault();

    setIsPending(true)

    let status = await login({email, password})

    setIsPending(false)

    if(status){

      setEmail('')

      setPassword('')

      if(location.state != null){

        return navigate(location.state.from.pathname)

      }

    }


  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar src={logo} sx={{ width: 80, height: 80 }}/>
          <Typography sx={{fontFamily: 'poppins-bold'}} component="h1" variant="h5">
            Connexion
          </Typography>
          {alert.open && 
            <Alert severity={alert.status} variant="filled" sx={{ width: '100%' }}>
              {alert.message}
            </Alert>
          }
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              error={emailError}
              helperText={emailHelper}
              value={email}
              onChange={(e)=> setEmail(e.target.value)}
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              fullWidth
              error={passwordError}
              helperText={passwordHelper}
              value={password}
              onChange={(e)=>setPassword(e.target.value)}
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              disabled={isPending}
              type="submit"
              fullWidth
              variant="contained"
              size='large'
              sx={{ mt: 3, mb: 4, height: 40 }}
            >
              {
                isPending ? 
                <CircularProgress />
                : (
                    <>
                      <LoginIcon sx={{ mr: 4 }}/>
                      Connexion
                    </>
                  )
              }
            </Button>
            <CssBaseline/>
            <Grid container>
              <Grid item xs>
                <Link sx={{fontFamily: 'poppins-bold'}} href="#" variant="body2" >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <NavLink  to="/register" >
                    Don't have an account? Sign Up
                </NavLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

