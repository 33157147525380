import React, { useRef } from 'react';
import useScrollTriggeredCountUp from './modules/counter';

const Statistic = () => {
    const ref = useRef(null);

    const projects = useScrollTriggeredCountUp(ref, 500); // 0 to 100 count-up
    const clients = useScrollTriggeredCountUp(ref, 2500); // 0 to 100 count-up
    const years = useScrollTriggeredCountUp(ref, 5); // 0 to 100 count-up
    const products = useScrollTriggeredCountUp(ref, 100); // 0 to 100 count-up

    return (  
        <>
            <section className="h-500 py-5" style={{backgroundColor: 'rgb(7, 7, 7)'}}>
                <div className="container">
                <div className="row row-cols-1 row-cols-md-4 g-4">
                    <div className="col">
                    <div className="card shadow">
                        <i className="bi bi-user-graduate"></i>
                        <div className="card-body">
                        <h5 className="h5 display-4 text-center" ref={ref}>+ {projects}</h5>
                        <p className="card-text text-center" style={{fontSize: '16px'}}>PROJECTS ACHEVES.</p>
                        </div>
                    </div>
                    </div>
                    <div className="col">
                    <div className="card shadow">
                        <div className="card-body">
                        <h5 className="h5 display-4 text-center">+ {clients}</h5>
                        <p className="card-text text-center" style={{fontSize: '16px'}}>CLIENTS SATISFAITS</p>
                        </div>
                    </div>
                    </div>
                    <div className="col">
                    <div className="card shadow">
                        <div className="card-body">
                        <h5 className="h5 display-4 text-center">+ {years}</h5>
                        <p className="card-text text-center" style={{fontSize: '16px'}}>ANNEES D'EXPERIENCE.</p>
                        </div>
                    </div>
                    </div>
                    <div className="col">
                    <div className="card shadow">
                        <div className="card-body">
                        <h5 className="h5 display-4 text-center">+ {products}</h5>
                        <p className="card-text text-center" style={{fontSize: '16px'}}>PRODUITS LANCES.</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        </>
    );
}
 
export default Statistic;