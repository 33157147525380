import React from 'react';
import CardItems from '../../component/front/CardItems';
import Team from '../../component/front/Team';
import Statistic from '../../component/front/Statistic';
import Products from '../../component/front/Products';
import Partner from '../../component/front/Partner';
import { motion } from 'framer-motion';
import { variants } from '../../component/motion/variants';
import PageHeader from '../../component/front/PageHeader';

const About = () => {
    return (  
        <>
            <PageHeader title="A propos de nous"/>
            <motion.section 
                className="bg-white pt-3"
                variants={variants.routerTrans}
                initial="hidden"
                animate="visible"
                >
                <div className="container">
                    <div className="row row-">
                        <div className="col-12 col-md-8 col-lg-8 mt-1 px-5">
                        <h2 className="h2 pb-3 mb-4">Qui sommes-nous?</h2>
                        <motion.p 
                            variants={variants.textFade1}
                            initial="hidden"
                            animate="visible"
                            className="text-dark text-start text-justify"
                            style={{fontSize: '16px'}}>
                            UAFRIKA se positionne en tant que leader panafricain dans la promotion de l'éducation, de la technologie et de l'entrepreneuriat. Notre mission fondamentale est d'outiller les rêves africains d'innovation et de prospérité durable. Nous croyons fermement en un continent où chaque aspiration est soutenue par des ressources adéquates pour se concrétiser. 
                        </motion.p>
                        <motion.p
                            variants={variants.textFade2}
                            initial="hidden"
                            animate="visible" 
                            className="text-dark text-start text-justify"
                            style={{fontSize: '16px'}}>
                            En tant qu'entreprise panafricaine, UAFRIKA s'engage à fournir des outils et des ressources essentiels pour stimuler l'innovation et favoriser une croissance durable à travers l'Afrique. 
                        </motion.p>
                        <motion.p
                            variants={variants.textFade3}
                            initial="hidden"
                            animate="visible"
                            className="text-dark text-start text-justify"
                            style={{fontSize: '16px'}}>
                            Nous proposons des solutions éducatives numériques de pointe, appuyons les startups technologiques émergentes et offrons un accompagnement stratégique aux entrepreneurs, contribuant ainsi activement à façonner un avenir prometteur pour le continent.
                        </motion.p>
                        <motion.h5 
                            variants={variants.textFade4}
                            initial="hidden"
                            animate="visible"
                            className="text-center"
                            style={{color: 'rgb(152, 86, 5)'}}>
                            <em> UAFRIKA révèle votre potentiel: le succès n'est pas à chercher, il est en vous. Cultivez-le, et laissez-le s'épanouir. <b>C'est notre credo</b>. </em>
                        </motion.h5>
                        </div>
                        <div className="col-12 col-md-4 col-lg- mt-4">
                        <img src={require('../../assets/style/images/asd.jpg')} className="img-fluid rounded shadow" alt=""/>
                        </div>
                    </div>
                </div>
            </motion.section>
            <motion.div
                style={{backgroundColor: '#1DAB45'}}
                animate={{
                scale: [1, 2, 2, 1, 1],
                rotate: [0, 0, 270, 270, 0],
                borderRadius: ["20%", "20%", "50%", "50%", "20%"],
            }}
            ></motion.div>
            <Statistic/>
            <CardItems/>
            <Products/>
            <Team/>
            <Partner/>
        </>
    );
}
 
export default About;