import { Alert, Button, CircularProgress, Snackbar, TextField } from '@mui/material';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react'
import SendIcon from '@mui/icons-material/Send';
import { FrontContext } from '../../../contexts/FrontContext';
import * as yup from 'yup';

const schema = yup.object({
    name: yup.string().required(),
    email: yup.string().required().email(),
    phone: yup.string().required(),
    subject: yup.string().required(),
    message: yup.string().required(),
})

function BookingForm() {
    const {sendMessage} = useContext(FrontContext)

    const [snack, setSnack] = useState(false)

    const [isPending, setIsPending] = useState(false)

  return (
    <>
        <Snackbar open={snack} >
            <Alert
                severity='success'
                variant="filled"
                sx={{ width: '100%' }}
            >
                message envoyé, nous vous reviendrons dans ci peu!
            </Alert>
        </Snackbar>
        <Formik
        validationSchema={schema}
        initialValues={{ 
            name: '', 
            email: '', 
            phone: '', 
            subject: '', 
            message: '', 
        }}
        onSubmit={ async (values, actions) => {

            setIsPending(true)
            const send = await sendMessage(values)
            setIsPending(false)
            setSnack(true)
            actions.resetForm()
            setTimeout(()=>{
                setSnack(false)
            }, 5000)
        }}
        >
        {(props) => (
            <>
                <div class="shadow px-5 py-4 rounded">
                    <p className='mb-4'>Remplissez ces champs pour reserver une entrevue avec Le responsable de Uafrika plateforme</p>
                    <TextField
                        onChange={props.handleChange('name')}
                        value={props.values.name} 
                        fullWidth 
                        label="Votre nom complet" 
                        size='small' 
                        variant="outlined"
                        sx={{mb: 4}}
                        error={props.errors.name ? true : false}
                        helperText={props.touched.name && props.errors.name}
                        />
                    <TextField 
                        onChange={props.handleChange('email')}
                        value={props.values.email} 
                        fullWidth 
                        label="Votre adresse mail" 
                        size='small' 
                        variant="outlined"
                        sx={{mb: 4}}
                        error={props.errors.email ? true : false}
                        helperText={props.touched.email && props.errors.email}
                        />
                    <TextField 
                        onChange={props.handleChange('phone')}
                        value={props.values.phone} 
                        fullWidth 
                        label="Votre numero de telephone" 
                        size='small' 
                        variant="outlined"
                        sx={{mb: 4}}
                        error={props.errors.phone ? true : false}
                        helperText={props.touched.phone && props.errors.phone}
                        />
                    <TextField 
                        onChange={props.handleChange('subject')}
                        value={props.values.subject} 
                        fullWidth 
                        label="Objet du message" 
                        size='small' 
                        variant="outlined"
                        sx={{mb: 4}}
                        error={props.touched.subject && props.errors.subject}
                        helperText={props.touched.subject && props.errors.subject}
                        />
                    <TextField 
                        onChange={props.handleChange('message')}
                        value={props.values.message} 
                        fullWidth 
                        label="Votre message" 
                        size='small' 
                        variant="outlined"
                        sx={{mb: 4}}
                        multiline
                        rows={4}
                        error={props.touched.message && props.errors.message}
                        helperText={props.touched.message && props.errors.message}
                        />
                    <div class="form-group ">
                        <button 
                            type='submit'
                            style={{backgroundColor: isPending? '#d3d3d3' : '#622506'}}
                            onClick={()=> props.handleSubmit()}
                            class="btn btn-success px-5">
                                {
                                    isPending ? 
                                    <CircularProgress size={20}/>
                                    : (
                                        <>
                                        Envoyé 
                                        <SendIcon sx={{ ml: 4 }}/>
                                        </>
                                    )
                                }
                        </button>
                    </div>
                </div>
            </>
        )}
        </Formik>
    </>
    )}

export default BookingForm